import styled from 'styled-components';
import {
  Select,
  Col,
  Row,
  Button,
  Modal,
  List,
  Input,
  DatePicker,
  Divider,
  Checkbox
} from 'antd';
export const CustomCol = styled(Col)`
  padding: 20px;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  background: white;
  cursor: pointer;
  flex: 1;

  border-right: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  &:hover {
    background-color: #e6f7ff;
    min-height: 200px;
  }
  &:hover {
    background-color: #e6f7ff;
    min-height: 200px;
  }
  &.today {
    background-color: #e6f7ff;
    cursor: pointer;
  }
  .patient-sheduler {
    text-align: start;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    padding: 10px 10px;
    position: relative;
  }

  .today-text {
    position: absolute;
    right: 5px;
    top: 11px;
    color: #5c5c5c; /* Optional: Add color for emphasis */
    font-weight: 500;
    font-size: 10px;
  }
  .col-content {
    text-align: center;
  }

  .day-text {
    font-size: 10px;
    font-weight: 500;
    color: #71717a;
  }

  .date-text {
    font-size: 28px;
    color: #2b2b2b;
    font-weight: 600;
  }
  /* Initially hide the button */

  /* Ensure proper layout and centering */
  /* .add-patient-button {
    display: block;
    margin: 10px auto; /* Center horizontally */
  .patientdetails {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    background-color: #deeff8;
    /* padding: 3px, 8px, 6px, 8px; */
    border-radius: 4px;
    border-left: 4px solid #0ea5e9;
    margin-bottom: 8px;
    margin-left: 4px;
    margin-right: 4px;
    margin-top: 2px;
    padding: 4px 0;
  }

  .patientNameCard {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 4px;
    color: #0369a1;
    padding-left: 5px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .closeIcon {
    font-size: 10px;
    width: 10px;
    height: 10px;
    padding-right: 3px;
  }
  .facilityNameCard {
    display: block;
    padding-left: 5px;
  }
  .bedcard {
    border: 1px solid #dfdfdf;
    border-radius: 3px;
    padding: 2px;

    font-size: 10px;
    font-weight: 500;
    color: #496d80;
    margin-right: 8px;
    display: inline-block; /* Ensures proper behavior for truncation */
    max-width: 99px; /* Set a maximum width for truncation */
    white-space: nowrap; /* Prevents text from wrapping to the next line */
    overflow: hidden; /* Hides the overflowed text */
    text-overflow: ellipsis;
  }
`;
export const DateRangeCol = styled(Col)`
  .ant-picker-input {
    border-radius: 8px;
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  .ant-picker-input > input {
    font-weight: 600;
    font-size: 14px;
    padding-left: 7px;
    /* background-color: red; */
    color: #2b2b2b; /* Change text color to white for better contrast */
  }
`;
export const RoasterRow = styled(Row)`
  width: 100%;
  border-top: 1px solid #dfdfdf;
  height: 9vh;
  .rosterhead {
    font-size: 18px;
    font-weight: 600;
    color: black;
    line-height: 24px;
  }
`;
export const CloseButton = styled(Button)`
  background: transparent !important;
  border: none !important;

  /* top: -3px;
  left: 153px; */

  width: auto;
  height: auto;
  /* position: relative; */
`;
export const StyledModal = styled(Modal)`
  .ant-modal-header {
    text-align: left; /* Ensure the header content is left-aligned */
  }

  .ant-modal-title {
    text-align: left;
    font-size: 18px; /* Adjust font size if needed */
    font-weight: bold; /* Adjust font weight if needed */
  }
  .ant-modal-body {
    min-height: 350px;
    max-height: 550px;
    height: 500px;
  }
  .ant-modal-content {
    width: 70%;
    border-radius: 9px !important; /* Ensure the radius applies to the modal content */
    overflow: hidden;
  }

  .ant-modal-title {
    font-weight: bold;
  }
`;
export const StyledSelect = styled(Select)`
  width: 100%;
  margin-bottom: 20px;
  border-radius: 8px !important;
  background-color: #f0f0f0; /* Light grey background */
  .ant-select-selector {
    height: 40px; /* Adjust height as needed */
    display: flex;
    align-items: center; /* Center the placeholder vertically */
  }
`;
export const FacilitySelect = styled(Select)`
  width: 100%;
  margin-bottom: 20px;
  border-radius: 8px !important;
  background-color: #f0f0f0; /* Light grey background */
  border-radius: 10px !important;
  height: 35px;
  /* Change background color of the select input */
  .ant-select-selection-item {
    font-weight: 600; /* Adjust font weight */
    font-size: 14px; /* Adjust font size */
    color: #2b2b2b;
  }
  .ant-select-selector {
    background-color: #f4f4f5 !important; /* Customize your color */
    height: 40px; /* Adjust height as needed */
    display: flex;
    align-items: center;
    border-radius: 8px !important;
    border: 1px solid #f4f4f5 !important; /* Neutral border color */

    /* Remove default blue border on hover and focus */
    &:hover,
    &:focus,
    &:active {
      border-color: #d9d9d9 !important; /* Same as default border color */
      box-shadow: none !important; /* Remove the blue shadow */
    }
  }

  /* Remove the focus and hover effect */
  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active {
    border-color: #d9d9d9 !important; /* Prevent blue border on focus */
    box-shadow: none !important; /* Remove blue shadow on focus */
  }
`;
export const DoctorSelect = styled(Select)`
  width: 100%;

  border-radius: 8px !important;
  background-color: #f0f0f0; /* Light grey background */
  border-radius: 6px !important;
  height: 35px;
  /* Change background color of the select input */
  .ant-select-selection-item {
    font-weight: 600; /* Adjust font weight */
    font-size: 18px; /* Adjust font size */
    color: #2b2b2b;
  }
  .ant-select-selector {
    background-color: #f4f4f5 !important; /* Customize your color */
    height: 40px; /* Adjust height as needed */
    display: flex;
    align-items: center;
    border-radius: 8px !important;
    border: 1px solid #f4f4f5 !important; /* Neutral border color */

    /* Remove default blue border on hover and focus */
    &:hover,
    &:focus,
    &:active {
      border-color: #d9d9d9 !important; /* Same as default border color */
      box-shadow: none !important; /* Remove the blue shadow */
    }
  }

  /* Remove the focus and hover effect */
  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active {
    border-color: #d9d9d9 !important; /* Prevent blue border on focus */
    box-shadow: none !important; /* Remove blue shadow on focus */
  }
`;
export const StyledList = styled(List)`
  .ant-list-item {
    display: flex;
    align-items: center;

    margin-bottom: 10px;
    border-radius: 8px; /* Rounded corners */
    padding: 10px; /* Add some padding */
    transition: background-color 0.3s;

    &:hover {
      background: #f1f3f5;
      /* Change background on hover */
    }
  }

  .ant-list-item-meta {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  .ant-list-item-meta-title {
    font-weight: bold;
    margin-bottom: 4px;
  }

  .ant-list-item-action {
    margin-left: auto; /* Push the button to the right */
  }

  .ant-btn-primary {
    border-radius: 20px; /* Rounded button */
  }
`;
export const AuthorizationModal = styled(Modal)`
  width: 294px !important;
  .ant-modal-content {
    border-radius: 8px;
  }
  .icon-wrapper {
    background-color: #f8e7d5;
    border-radius: 50%;
    padding: 10px; /* Adjust padding to control the size of the background circle */
    margin-bottom: 10px; /* Space between the icon and the text */
  }
  .warning-icon {
    font-size: 26.67px;
    color: #faad14; /* Warning color */
  }
  .icon {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .authorization-modal .authorization-button:hover {
    background-color: #40a9ff;
  }
`;
export const AuthorizationModalContent = styled.div`
  h3 {
    font-size: 18px !important;
    font-weight: 400 !important;
    color: #151515 !important; /* Change the color of the heading */
    margin-bottom: 10px !important;
    text-align: center !important; /* Center-align both lines */
  }

  p {
    font-size: 12px !important;
    color: #474747 !important;
    line-height: 1.6 !important; /* Improve readability */
    margin-bottom: 20px !important;
    font-weight: 400;
    text-align: center !important;
  }

  .authorization-button {
    background-color: #1890ff !important;
    border-color: #1890ff !important;
    border-radius: 8px !important;
    display: flex;
    justify-content: center;
    width: 100%;
    /* padding: 10px 20px !important; */
  }
`;
export const AddButton = styled(Button)`
  background-color: white !important;
  color: #47a4ea !important;

  &:hover {
    background-color: #47a4ea !important;
    color: white !important;
  }
`;
export const AddpatientButton = styled(Button)`
  margin: auto 0;
  align-items: center;
  margin-right: 15px;
  width: 50px !important;
  border: none !important;
  box-shadow: none !important;
  color: black !important;
  background: none !important;
  font-weight: 600;
  &:hover {
    border-color: none !important; /* Change border color on hover */
    border: none !important;
  }
  .bold-icon {
    font-weight: bold;
  }
`;
export const StyledSearchBar = styled(Input)`
  background-color: white;
  margin: 16px 0;
  border-radius: 4px;
  padding: 8px;
  border: 1px solid #d9d9d9; /* Light grey border */
  width: 100%; /* Full width */
  box-sizing: border-box;
`;

export const FacilityButton = styled(Button)`
  margin: auto 0;
  align-items: center;
  margin-right: 15px;
  border: none !important;
  box-shadow: none !important;
  color: #2b2b2b !important;
  background: #f4f4f5 !important;
  font-size: 14px;
  font-weight: 500;
  font-weight: 600;
  border-radius: 8px;
  &:hover {
    border-color: none !important; /* Change border color on hover */
    border: none !important;
  }
`;
export const SearchInputRosterStyle = styled(Input)`
  width: 100%;
  height: 24px;
  margin: auto 0;
  align-items: center;
  border-top: 1px solid #d9d9d9;
  &.ant-input-affix-wrapper {
    background: #ffffff;
    border: none;
    border-radius: none;
  }
  &:hover {
    border-color: none !important; /* Change border color on hover */
    border: none !important;
  }
  @media (max-width: 925px) {
    width: 100%;
  }
`;
export const SearchFacilityDivider = styled(Divider)`
  margin: -3px 0;
`;
export const StyledDivider = styled(Divider)`
  margin: 5px;
`;
export const StyledCheckBox = styled(Checkbox)`
  margin-left: 10px;
  padding: 7px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  gap: 8px;
  /* background-color: red; */
  display: flex;
  flex-direction: row;
  place-items: center;
  &:hover {
    background-color: #f4f4f5;
  }
  /* Target the checkbox size */
`;
export const FacilityFilterroster = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  /* border: none; */
  border-bottom: 1px solid #f1f3f5;
`;
export const FacilityFilterFooter = styled.div`
  border-top: 1px solid #f1f3f5;
  padding: 8px;
`;
export const PatientRoasterFacilitySectionexport = styled.div`
  border-radius: 8px;
  padding: 10px;
  background-color: white;
`;
export const FacilityFilterButton = styled(Button)`
  background: transparent !important;
  margin-left: 10px !important;
  color: #47a4ea !important;
  border-color: #47a4ea !important;
`;
