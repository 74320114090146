import React, { useRef, useEffect } from 'react';
import { Row, Form, Input, Col, Switch, Select, Divider, Avatar } from 'antd';
import { SubmitButtonCol, AddButton } from '../styledComponents.js';
import ReactQuill from 'react-quill';
import { ArrowDownOutlined } from '@ant-design/icons';

import { useState } from 'react';
import DragIndicatorIcon from '../../../Assets/Icons/DragIndicatorIcon.js';

import { useSelector } from 'react-redux';
import { StyledTag, SubmitButton } from '../KeyToPhrase/styledComponents.js';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.snow.css';
import { StyledModalContent } from '../Dictionary/styledComponets.js';
import { StyledTagContainer } from './styledComponets.js';
const AddDictionaryKeyToPhraseForm = ({
  selectedItem,
  submitHandler,
  submitLoading,
  dynamicEndPoints,
  showTable,
  handleSubmit,
  handleCancel
}) => {
  const [form] = Form.useForm();
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const [dynamicEnable, setDynamicEnable] = useState(
    selectedItem ? selectedItem.dynamic : false
  );

  const [selectedDynamicEndPoint, setSelectedDynamicEndPoint] = useState(() => {
    if (selectedItem) {
      return selectedItem.targetEndPoint;
    } else if (dynamicEndPoints && dynamicEndPoints.length > 0) {
      return dynamicEndPoints[0].fieldUiValue;
    } else {
      return '';
    }
  });

  const quillRef = useRef(null);

  const [endpointChangeModalVisible, setEndpointChangeModalVisible] =
    useState(false);

  const handleEndPointChange = value => {
    const regex = /(\$\{(.*?)\})/g;
    if (
      selectedDynamicEndPoint !== value &&
      form.getFieldValue('phrase')?.match(regex)?.length > 0
    ) {
      setEndpointChangeModalVisible(true);
    } else {
      setSelectedDynamicEndPoint(value);
    }
  };

  const handleEndPointChangeConfirm = () => {
    const phraseData = form.getFieldValue('phrase');
    const data = form.getFieldsValue();
    form.setFieldsValue({
      ...data,
      phrase: phraseData.replace(/(\$\{(.*?)\})/g, '')
    });
    setSelectedDynamicEndPoint(form.getFieldValue('targetEndPoint'));
    setEndpointChangeModalVisible(false);
  };

  const handleEndPointChangeCancel = () => {
    const data = form.getFieldsValue();
    form.setFieldsValue({
      ...data,
      targetEndPoint: selectedDynamicEndPoint
    });
    setEndpointChangeModalVisible(false);
  };

  const dropHandler = e => {
    const end = quillRef.current.getEditor().getLength();

    quillRef.current.getEditor().setSelection(end);

    quillRef.current.getEditor().focus();

    const quill = quillRef.current.getEditor();

    const data = e.dataTransfer.getData('text/html');

    const range = quill.getSelection();

    quill.clipboard.dangerouslyPasteHTML(range.index, data, 'user');
  };
  const dragStartHandler = e => {
    e.dataTransfer.setData('text/html', '${' + e.target.textContent + '}');
  };
  const dynamicStyle = !dynamicEnable
    ? { height: '245px' }
    : {
        height: '245px',
        width: '470px'
      };

  const modules = {
    toolbar: [['bold', 'italic', 'underline', 'strike', 'clean']],
    clipboard: {
      matchVisual: false
    }
  };

  const handleFormValuesChange = (changedValues, allValues) => {
    // Check if any field has been touched
    const isFormChanged = Object.keys(changedValues).length > 0;
    setSubmitDisabled(!isFormChanged); // Disable submit button if no changes
  };
  useEffect(() => {}, [form, dynamicEnable]); // Add dependencies to ensure useEffect triggers when form or dynamicEnable changes

  return (
    <>
      <Form
        form={form}
        layout='vertical'
        onFinish={values => submitHandler(values)}
        onValuesChange={handleFormValuesChange}
        initialValues={{
          key: selectedItem ? selectedItem.key : '',
          global: selectedItem ? selectedItem.global : false,
          active: selectedItem ? selectedItem.active : false,
          dynamic: selectedItem ? selectedItem.dynamic : false,
          targetEndPoint:
            selectedItem && selectedItem.targetEndPoint
              ? selectedItem.targetEndPoint
              : selectedDynamicEndPoint,
          phrase: selectedItem ? selectedItem.phrase : ''
        }}
      >
        <Row justify={'center'}>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Form.Item
                  label={
                    <label
                      style={{
                        fontSize: '16px',
                        fontWeight: '600',
                        color: '#2B2B2B'
                      }}
                    >
                      Key
                    </label>
                  }
                  name='key'
                  rules={[{ required: true, message: 'Key is required' }]}
                >
                  <Input
                    size='large'
                    onDrop={event => {
                      event.preventDefault();
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify='center' align='middle'>
              <Col span={11} style={{ textAlign: 'right' }}>
                <Divider style={{ margin: '0', height: '2px' }} />
              </Col>
              <Col span={2} style={{ textAlign: 'center' }}>
                <Avatar
                  size={34}
                  icon={<ArrowDownOutlined style={{ color: '#000000' }} />}
                  style={{
                    backgroundColor: '#ffffff',
                    border: '1px solid #E1E4EB'
                  }}
                />
              </Col>
              <Col span={11} style={{ textAlign: 'left' }}>
                <Divider style={{ margin: '0', height: '2px' }} />
              </Col>
            </Row>
            <Row
              gutter={16}
              className='DynamicRow'
              align={'middle'}
              style={{ marginBottom: '16px' }}
            >
              <Col span={7}>
                <label
                  style={{
                    fontSize: '16px',
                    fontWeight: '600',
                    color: '#2B2B2B'
                  }}
                >
                  Phrase
                </label>
              </Col>
              <Col span={2}>
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: '500',
                    color: '#2B2B2B',
                    display: 'inline-block',
                    verticalAlign: 'middle'
                  }}
                >
                  Type
                </span>
              </Col>
              <Col span={4}>
                <Form.Item
                  name='dynamic'
                  style={{ marginBottom: 0, marginRight: 'auto' }}
                >
                  <Switch
                    checked={dynamicEnable}
                    checkedChildren='Dynamic'
                    unCheckedChildren='Static'
                    onChange={() => {
                      form.setFieldValue({
                        ...form.getFieldsValue(),
                        dynamic: !dynamicEnable
                      });
                      setDynamicEnable(!dynamicEnable);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: '500',
                    color: '#2B2B2B',
                    display: 'inline-block',
                    verticalAlign: 'middle'
                  }}
                >
                  Dynamic Category:
                </span>
              </Col>
              <Col span={6}>
                <Form.Item
                  name='targetEndPoint'
                  style={{
                    marginBottom: 0,
                    marginRight: 'auto'
                  }}
                  rules={[
                    { required: true, message: 'Dynamic Category is required' }
                  ]}
                >
                  <Select
                    placeholder={'Please select Dynamic Category'}
                    disabled={!dynamicEnable}
                    onChange={handleEndPointChange}
                    style={{ borderRadius: '8px' }}
                  >
                    {dynamicEndPoints &&
                      dynamicEndPoints.map(endPoint => (
                        <Select.Option
                          key={endPoint.fieldUiValue}
                          value={endPoint.fieldUiValue}
                        >
                          {endPoint.fieldUiName}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Row className='dynamicEditor' justify={'space-between'}>
                  <Col span={dynamicEnable ? 10 : 24}>
                    <div
                      id='quilldiv'
                      onDrop={dropHandler}
                      onDragOver={e => e.preventDefault()}
                    >
                      <Form.Item
                        // label='Phrase'
                        name='phrase'
                        rules={[
                          { required: true, message: 'Phrase is required' }
                        ]}
                      >
                        <ReactQuill
                          style={dynamicStyle}
                          modules={modules}
                          ref={quillRef}
                          theme='snow'
                          className='custom-quill-editor'
                        />
                        {/* <Input.TextArea
                        style={{ height: '40vh' }}
                        ref={phaseTextAreaRef}
                      /> */}
                      </Form.Item>
                    </div>
                  </Col>
                  {dynamicEnable ? (
                    <>
                      <Col span={8}>
                        <StyledTagContainer>
                          {dynamicEndPoints &&
                            dynamicEndPoints
                              .find(
                                endpoint =>
                                  endpoint.fieldUiValue ===
                                  selectedDynamicEndPoint
                              )
                              ?.avilableFields.map(dynamicFields => {
                                return (
                                  <StyledTag
                                    key={dynamicFields}
                                    draggable='true'
                                    onDragStart={dragStartHandler}
                                  >
                                    <DragIndicatorIcon color={'#000000'} />
                                    {dynamicFields}
                                  </StyledTag>
                                );
                              })}
                        </StyledTagContainer>
                      </Col>
                    </>
                  ) : null}
                </Row>
              </Col>
            </Row>
            <Row>
              <SubmitButtonCol span={15}></SubmitButtonCol>
              {
                <SubmitButtonCol span={9}>
                  <SubmitButton
                    key='cancel'
                    size='large'
                    shape='round'
                    style={{ marginTop: '60px', marginRight: '12px' }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </SubmitButton>
                  <SubmitButton
                    htmlType='submit'
                    type='primary'
                    size='large'
                    shape='round'
                    loading={submitLoading}
                    onClick={handleSubmit}
                    style={{ marginTop: '60px', marginRight: '12px' }}
                  >
                    Submit
                  </SubmitButton>
                </SubmitButtonCol>
              }
            </Row>
          </Col>
        </Row>
      </Form>

      <StyledModalContent
        open={endpointChangeModalVisible}
        centered
        mask={false}
        footer={null}
        closable={false}
        onCancel={handleEndPointChangeCancel}
      >
        <h2 className='styledHeading'>
          Do you really want to change Dynamic Category ?
        </h2>
        <p className='styledParagraph'>
          All the dynamic tags associated with it will be removed.
        </p>
        <div className='button-container'>
          <AddButton
            ghost
            type='primary'
            shape='round'
            onClick={handleEndPointChangeCancel}
          >
            <span className='buttonValue'> No,</span>Cancel
          </AddButton>
          <AddButton danger shape='round' onClick={handleEndPointChangeConfirm}>
            <span className='buttonValue'>Yes,</span>Change
          </AddButton>
        </div>
      </StyledModalContent>
    </>
  );
};

export default AddDictionaryKeyToPhraseForm;
