import React, { useEffect } from 'react';
import Layout from './Layout';
import { useHistory, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../CustomComponents/Spinner';
import Dashboard from './Dashboard';
import Doctors from './Doctors';
import Settings from './Settings';
import Reviewers from './Reviewers';
import ProfileInformation from './Settings/Profile';
import { AddUser, ClearUser } from '../../Redux/Actions/User';
import Transcriptionists from './Transcriptionists';
import JobList from './JobList';
import Editor from './Editor';
import EhrConfiguration from './Settings/EhrConfiguration';
import HL7 from './Hl7';
import Payment from './Billing/Payment';
import Billing from './Billing';
import Subscription from './Billing/Subscription';
import PaymentLog from './Billing/PaymentLog';
import { disconnectSocket } from '../../Socket';
import { useIdleTimer } from 'react-idle-timer';
import { IDLE_LOGOUT_TIME_MINUTES } from '../../Constants/StringConstants';
import Audit from './Audit';
import { logoutAPI } from '../../Apis/Auth';
import Admins from './Admins';
import Biller from './Biller';
import Billers from './Billers';
import DeviceConfiguration from './Settings/DeviceConfiguration';
import KeyToPhrase from './KeyToPhrase';
import Dictation from './Dictation';
import VeriyfEmr from './VerifyEmr';
import Facility from './Facility';
import NewEditor from './NewEditor';
import NewDashboard from './NewDashboard';
import BillersJobs from './BillersJobs';
import Roaster from './Roaster';
import Dictionary from './Dictionary';
import BillerAudit from './BillerAudit';
import DictionaryTemplates from './DictionaryTemplates';
const Content = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userState = useSelector(state => state.userReducer);

  useEffect(() => {
    const user = localStorage.getItem('user');
    const loggedIn = localStorage.getItem('loggedIn');
    if (!user && !loggedIn) {
      history.push('/login');
    } else {
      dispatch(AddUser(JSON.parse(user)));
    }
    // eslint-disable-next-line
  }, []);

  const handleOnIdle = event => {
    getLastActiveTime();
    logout();
  };

  const { getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * IDLE_LOGOUT_TIME_MINUTES,
    onIdle: handleOnIdle,
    debounce: 500
  });

  const logout = () => {
    logoutAPI(userState.sessionId).then(resp => {
      disconnectSocket();
      dispatch(ClearUser());
      localStorage.clear();
      history.push('/login');
    });
  };

  return userState.id ? (
    <Layout>
      <Route
        exact
        path='/'
        component={userState.superAdmin ? NewDashboard : Dashboard}
      />
      <Route
        exact
        path='/dashboard'
        component={userState.superAdmin ? NewDashboard : Dashboard}
      />
      <Route exact path='/dashboard/admins' component={Admins} />
      <Route path='/dashboard/biller' component={Biller} />
      <Route path='/dashboard/doctors' component={Doctors} />
      <Route path='/dashboard/reviewers' component={Reviewers} />
      <Route path='/dashboard/billers' component={Billers} />
      <Route path='/dashboard/roaster' component={Roaster} />
      <Route
        path='/dashboard/transcriptionists'
        component={Transcriptionists}
      />
      <Route exact path='/dashboard/settings' component={Settings} />
      <Route
        path='/dashboard/settings/profile'
        component={ProfileInformation}
      />
      <Route path='/dashboard/dictionary' component={Dictionary} />
      <Route
        path='/dashboard/dictionary-templates'
        component={DictionaryTemplates}
      />
      <Route path='/dashboard/biller-audit' component={BillerAudit} />
      <Route path='/dashboard/hl7' component={HL7} />
      <Route
        path='/dashboard/settings/ehr-configuration'
        component={EhrConfiguration}
      />
      <Route
        path='/dashboard/settings/device-configuration'
        component={DeviceConfiguration}
      />
      <Route path='/dashboard/jobs-list' component={JobList} />
      <Route path='/dashboard/editor/:jobId' component={Editor} />
      <Route path='/dashboard/new-editor/:jobId' component={NewEditor} />
      <Route
        exact
        path='/dashboard/billers-jobs/:jobId'
        component={BillersJobs}
      />

      <Route exact path='/dashboard/billing' component={Billing} />
      <Route path='/dashboard/billing/manage-cards' component={Payment} />
      <Route path='/dashboard/billing/payment-logs' component={PaymentLog} />
      <Route
        path='/dashboard/billing/manage-subscription'
        component={Subscription}
      />
      <Route exact path='/dashboard/audit' component={Audit} />
      <Route
        exact
        path='/dashboard/settings/key-to-phrase'
        component={KeyToPhrase}
      />
      <Route exact path='/dashboard/settings/dictation' component={Dictation} />
      <Route
        path='/dashboard/verify-emr/:clinicId/user/:userId'
        component={VeriyfEmr}
      />
      <Route exact path='/dashboard/settings/facility' component={Facility} />
      <Route
        exact
        path='/dashboard/facility/:facilityId'
        component={EhrConfiguration}
      />
    </Layout>
  ) : (
    <Spinner height={'100vh'} />
  );
};
export default Content;
