import React from 'react';

function RosterIcon({ size, color }) {
  return (
    <span className='anticon calendar-icon'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={size ? size : '25'}
        height={size ? size : '25'}
        fill='none'
        viewBox='0 0 25 25'
      >
        <path
          d='M19.4336 4.09375H5.43359C4.32902 4.09375 3.43359 4.98918 3.43359 6.09375V20.0938C3.43359 21.1983 4.32902 22.0938 5.43359 22.0938H19.4336C20.5382 22.0938 21.4336 21.1983 21.4336 20.0938V6.09375C21.4336 4.98918 20.5382 4.09375 19.4336 4.09375Z'
          className='calendar-path'
          stroke={color ? color : '#666'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M16.4336 2.09375V6.09375'
          className='calendar-path'
          stroke={color ? color : '#666'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.43359 2.09375V6.09375'
          className='calendar-path'
          stroke={color ? color : '#666'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.43359 10.0938H21.4336'
          className='calendar-path'
          stroke={color ? color : '#666'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </span>
  );
}

export default RosterIcon;
