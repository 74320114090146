import React from 'react';

function FacilityFilterIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='17'
      fill='none'
      viewBox='0 0 17 17'
    >
      <path
        d='M2 4.66699H14M3.33333 8.00033H12.6667M4.66667 11.3337H11.3333'
        stroke='black'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}

export default FacilityFilterIcon;
