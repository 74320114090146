import React, { useEffect, useState } from 'react';
import PageTitle from '../../../../Utils/PageTitle';
import CustomTypography from '../../../CustomComponents/CustomTypography';
import BreadCrumbComponent from '../../../CustomComponents/BreadCrumbComponent';
import {
  DeviceConfigurationWrapper,
  ItemsListContainer,
  SelectedItemsListContainer,
  ListTitle,
  SelectedEhrFieldsContainer,
  SelectedEhrField,
  StyledButton,
  TitleUnderLine,
  EmptyMessage,
  StyledTree
} from './styledComponents';
import { ContainerRow, TitleRow } from '../../styledComponents';
import { useSelector } from 'react-redux';
import {
  saveEhrFields,
  updateFacilityWorkType
} from '../../../../Apis/Facility';
import {
  getAvailableEhrFields,
  getFacilityWorkTypes
} from '../../../../Apis/Facility';
import { notification } from 'antd';
import { SaveOutlined, EditOutlined } from '@ant-design/icons';
import Spinner from '../../../CustomComponents/Spinner';
import { getUserFacilities } from '../../../../Apis/User';
import { StyledSelect } from './styledComponents';
const { Option } = StyledSelect;

const DeviceConfiguration = props => {
  const [ehrFields, setEhrFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const theme = useSelector(state => state.themeReducer);
  const [facilities, setFacilities] = useState([]);
  const [facilityIds, setFacilityIds] = useState();
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [expandedFields, setExpandedFields] = useState(['vitalsign']);
  const [facilityWorkTypeOptions, setFacilityWorkTypeOptions] = useState([]);
  const [facilityWorkType, setFacilityWorkType] = useState();
  let BreadCrubItems = [
    {
      name: 'Settings',
      path: '/dashboard/settings'
    },
    {
      name: 'Device Config',
      path: '/dashboard/settings/device-configuration'
    },
    {
      name: 'Device Configuration'
    }
  ];
  const handleSearch = searchValue => {
    // Perform search-related actions if needed
  };
  const handleChange = value => {
    setFacilityWorkType(value);
    setIsEdit(true);
    setSaveDisabled(false);
  };
  const onCheck = (checkedKeys, info) => {
    setSaveDisabled(false);
    setEhrFields(
      ehrFields.map(item => {
        if (
          item?.fieldType === 'Option' &&
          checkedKeys.includes(item?.fieldName)
        ) {
          item.fieldValues = item?.fieldValues?.map(subItem => {
            subItem.selected = true;
            return subItem;
          });
          item.selected = true;
        } else if (item?.fieldType === 'Option') {
          item.fieldValues = item?.fieldValues?.map(subItem => {
            if (checkedKeys.includes(subItem.fieldName)) {
              subItem.selected = true;
            } else {
              subItem.selected = false;
            }
            return subItem;
          });
        } else if (checkedKeys?.includes(item?.fieldName)) {
          item.selected = true;
        } else {
          item.selected = false;
        }
        return item;
      })
    );
  };

  const onExpand = (expandedKeys, expanded) => {
    setExpandedFields(expandedKeys);
  };
  const save = () => {
    setSaveDisabled(true);
    setSaveLoading(true);
    saveEhrFields([...ehrFields], facilityIds, facilityWorkType)
      .then(response => {
        setSaveLoading(false);
        if (response.data.success) {
          notification['success']({
            message: 'Success',
            description: response.data.message
          });
          setIsEdit(false);
        } else {
          notification['error']({
            message: 'Failes',
            description: response.data.message
          });
        }
      })
      .catch(err => {
        setSaveLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (facilityIds) {
      setLoading(true);
      getFacilityWorkTypes(facilityIds)
        .then(res => {
          if (res.data) {
            setFacilityWorkTypeOptions(
              res.data.workTypes ? res.data.workTypes : []
            );
            if (res.data.workType) {
              setFacilityWorkType(res.data.workTypes ? res.data.workType : []);
            } else {
              setFacilityWorkType('Select work type');
            }
            setLoading(false);
            //setEhrFields(response.data.result);
          } else {
            setLoading(false);
          }
          setLoading(false);
          //const options = getOptionObjectsFromArrayFacility(res.data.facilityWorkType);

          //setFacilityWorkTypeOptions(options);
          //console.log("End",options)
        })
        .catch(err => {
          setLoading(false);
          console.log(err);
        });
    }
  }, [facilityIds]);
  useEffect(() => {
    if (facilityIds) {
      setLoading(true);
      getAvailableEhrFields(facilityIds)
        .then(response => {
          if (response.data.success) {
            setLoading(false);
            setEhrFields(response.data.result);
          } else {
            setLoading(false);
            notification['error']({
              message: 'Failed',
              description: response.data.message
            });
          }
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          console.log(err);
        });
    }

    // eslint-disable-next-line
  }, [facilityIds]);

  const getFacilities = () => {
    getUserFacilities().then(response => {
      if (response.data.success) {
        const enabledFacilities = response.data.result.filter(facility => {
          return !facility.disabled;
        });
        setFacilities(enabledFacilities);
        setFacilityIds(enabledFacilities[0]?.facilityId);
      } else {
        notification['error']({
          message: 'User Facilities Fetch Failed',
          description: response.data.message
        });
      }
    });
  };

  useEffect(() => {
    let newSelectedFields = [];
    ehrFields
      .filter(item => {
        return item.fieldType === 'String';
      })
      .filter(item => {
        return item.selected;
      })
      .forEach(item => {
        newSelectedFields.push(item);
      });

    ehrFields
      .filter(item => {
        return item.fieldType === 'Option';
      })
      .forEach(item => {
        item.fieldValues
          .filter(subItem => {
            return subItem.selected;
          })
          .forEach(subItem => {
            newSelectedFields.push(subItem);
          });
      });

    setSelectedFields(newSelectedFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ehrFields]);

  useEffect(() => {
    getFacilities();
  }, []);

  return (
    <>
      <PageTitle title='Device Configuration' />
      <ContainerRow device={true}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <div gutter={[0, 40]}>
            {' '}
            <TitleRow span={24}>
              <CustomTypography color={theme['@text-color']}>
                Device Configuration
              </CustomTypography>
            </TitleRow>
            <BreadCrumbComponent items={BreadCrubItems} />
          </div>
          <StyledSelect
            value={facilityIds}
            placeholder='Filter'
            onSelect={setFacilityIds}
          >
            {facilities.length > 0 &&
              facilities.map(item => {
                return (
                  <Option value={item.facilityId}>{item.facilityName}</Option>
                );
              })}
          </StyledSelect>
        </div>

        {loading ? (
          <Spinner height={'70vh'} />
        ) : (
          <DeviceConfigurationWrapper>
            <ItemsListContainer>
              <ListTitle>Available Fields</ListTitle>
              <StyledTree
                checkable
                selectable={false}
                disabled={!isEdit}
                autoExpandParent
                expandedKeys={[...expandedFields]}
                onExpand={onExpand}
                checkedKeys={selectedFields.map(selectedField => {
                  return selectedField.fieldName;
                })}
                showLine
                onCheck={onCheck}
                treeData={ehrFields?.map(item => {
                  return {
                    title: item.fieldUIName,
                    key: item.fieldName,

                    children:
                      item.fieldType === 'Option'
                        ? item?.fieldValues?.map(subItem => {
                            return {
                              title: subItem.fieldUIName,
                              key: subItem.fieldName
                            };
                          })
                        : null
                  };
                })}
              />
              <ListTitle>Work type</ListTitle>

              <StyledSelect
                disabled={!isEdit}
                value={facilityWorkType}
                style={{
                  width: 185,
                  marginTop: 0
                }}
                showSearch={true}
                onSearch={handleSearch}
                onChange={handleChange}
              >
                {facilityWorkTypeOptions.length > 0 &&
                  facilityWorkTypeOptions.map((item, index) => (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  ))}
              </StyledSelect>
            </ItemsListContainer>
            <SelectedItemsListContainer>
              <ListTitle selected={true}>Selected Fields</ListTitle>
              <TitleUnderLine />
              <SelectedEhrFieldsContainer>
                {selectedFields.length > 0 ? (
                  selectedFields.map(item => {
                    return (
                      <SelectedEhrField>{item.fieldUIName}</SelectedEhrField>
                    );
                  })
                ) : (
                  <EmptyMessage selected={true}>
                    No selected fields
                  </EmptyMessage>
                )}
              </SelectedEhrFieldsContainer>

              <StyledButton
                type='primary'
                icon={isEdit ? <SaveOutlined /> : <EditOutlined />}
                onClick={() => (isEdit ? save() : setIsEdit(true))}
                disabled={isEdit && saveDisabled}
                loading={saveLoading}
              >
                {isEdit ? 'Save' : 'Edit'}
              </StyledButton>
            </SelectedItemsListContainer>
          </DeviceConfigurationWrapper>
        )}
      </ContainerRow>
    </>
  );
};

export default DeviceConfiguration;
