import axiosInstance from '../Config/AxiosInstance';

const PREFIX = `clinic/doctor/patient-roster`;
export const getPatient = (facilityId, searchString = '') => {
  return axiosInstance.get(
    PREFIX + `/patients?facilityId=${facilityId}&searchValue=${searchString} `
  );
};
export const addPatientToRoster = (dateTime, facilityId, patientIds) => {
  const payload = {
    dateTime,
    facilityId,
    patientIds
  };

  return axiosInstance.post(PREFIX, payload);
};

export const getPatientRosterOfDoctor = (endDate, facilityId, startDate) => {
  return axiosInstance.get(
    PREFIX +
      `?endDate=${endDate}&facilityIds=${facilityId}&startDate=${startDate}  `
  );
};
export const removePatientCard = rosterId => {
  return axiosInstance.delete(PREFIX + `?rosterId=${rosterId}`);
};
export const getBillerAuthor = facilityId => {
  return axiosInstance.get(PREFIX + `/biller/authors `);
};
