import React, { useState, useEffect } from 'react';

import {
  Layout,
  Menu,
  DatePicker,
  Button,
  Space,
  Typography,
  Row,
  Col,
  List,
  Modal,
  Input,
  notification,
  Select,
  Checkbox,
  Dropdown
} from 'antd';
import Spinner from '../../CustomComponents/Spinner';
// import FacilityFilterIcon from '../../../Assets/Icons/FacilityFilterIcon';
import FacilityFilterIcon from '../../../Assets/Icons/FacilityFilterIcon';
import { FilterOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { CalendarOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import {
  CustomCol,
  RoasterRow,
  CloseButton,
  StyledList,
  StyledSelect,
  StyledModal,
  AuthorizationModal,
  AddpatientButton,
  StyledSearchBar,
  FacilityButton,
  FacilityFilterButton,
  StyledDatePicker,
  SearchInputRosterStyle,
  SearchFacilityDivider,
  DateRangeCol,
  AddButton,
  StyledCheckBox,
  FacilityFilterroster,
  FacilityFilterFooter,
  FacilitySelect,
  AuthorizationModalContent,
  DoctorSelect,
  StyledDivider
} from './styledComponents';
import { getUserFacilities } from '../../../Apis/User';
import {
  addPatientToRoster,
  getPatient,
  getPatientRosterOfDoctor,
  removePatientCard,
  getBillerAuthor
} from '../../../Apis/Patient Roster';
import { Avatar } from 'antd';
import ConfirmationModal from '../../CustomComponents/ConfirmationModal';
import { DeleteConfirmationMessage } from '../Dictation/styledComponents';
import DeleteIcon from '../../../Assets/Icons/DeleteIcon';
import { SearchInputStyle } from '../styledComponents';
import { SearchDivider } from '../KeyToPhrase/styledComponents';

const { Header, Content } = Layout;

const { Title, Text } = Typography;
const { Search } = Input;
const { RangePicker } = DatePicker;
const size = 'large';
const { Option } = Select;
const Roaster = () => {
  const [events, setEvents] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [patientName, setPatientName] = useState('');
  const [facilities, setFacilities] = useState([]);
  const [billerAuthor, setBillerAuthor] = useState([]);
  const [authorId, setAuthorId] = useState([]);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [patients, setPatients] = useState([]);
  const [hoveredDate, setHoveredDate] = useState(null);
  const [facility, setFacility] = useState(null);
  const [biller, setBiller] = useState('');
  const [searchString, setSearchString] = useState('');
  const [searchStringFacility, setSearchStringFacility] = useState('');

  const [loader, setLoader] = useState(true);
  const [itemId, setItemId] = useState('');
  const [loader1, setLoader1] = useState(false);
  const [selectDate, setSelectDate] = useState(null);
  // const dateRange = [new Date(), new Date()]; // Example with JavaScript Date objects
  const [deleteModal, setDeleteModal] = useState(false);
  const [dateRange, setDateRange] = useState([
    moment().startOf('week'),

    moment().endOf('week')
  ]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const history = useHistory();

  const handlePccOk = () => {
    history.push('/dashboard');
    setIsModalVisible(false);
  };

  const handlePccCancel = () => {
    setIsModalVisible(false);
  };
  const userData = JSON.parse(localStorage.getItem('user'));
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  useEffect(() => {
    if (userData.userType === 'biller') {
      if (authorId.length > 0) {
        handleApplyFilter();
      }
    } else {
      console.log(userData.userType, 'use');

      handleApplyFilter();
    }
    // setLoader(true);

    // eslint-disable-next-line
  }, [facilities, dateRange, authorId]);
  useEffect(() => {
    if (userData.userType === 'biller') {
      getFacilities(authorId);
    }
    // eslint-disable-next-line
  }, [authorId]);
  useEffect(() => {
    // setLoader(true);

    if (userData.userType === 'biller') {
      getBillerAuthor().then(response => {
        if (response.data.success) {
          setBillerAuthor(response.data.result);
          setLoader(false);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
          setLoader(false);
        }
      });
    } else {
      getFacilities();
    }
    // getPatientRosterOfDoctor(endDate, facilityId, startDate);
  }, []);
  useEffect(() => {
    // getPatientRosterOfDoctor(endDate, facilityId, startDate);
  }, [dateRange]);

  const [view, setView] = useState('week');

  const daysOfWeek = Array.from({ length: 7 }, (_, i) =>
    moment(dateRange[0]).startOf('week').add(i, 'days')
  );

  const today = moment().format('YYYY-MM-DD');

  const handleDateClick = date => {
    setSelectedDate(date);

    setIsModalOpen(true); // Open the modal
  };
  const addPatientToRoster1 = patientId => {
    addPatientToRoster(
      Math.floor(
        moment(selectedDate).hour(12).minute(0).second(0).valueOf() / 1000
      ), // Set time to 12:00 PM (noon) and convert to seconds
      facility.value,
      [patientId]
    ) // Assuming this is your API call
      .then(response => {
        if (response.data.success) {
          // If the API call succeeds, show a success message
          notification['success']({
            message: 'Success',
            description: 'Patient has been successfully added to the roster.'
          });
          handleApplyFilter();
        } else {
          // If the API call fails but the request went through
          notification['error']({
            message: 'Failed',
            description:
              response.data.message ||
              'Failed to add patient to the roster. Please try again.'
          });
        }
        setIsModalOpen(false);
      });
  };
  const handleMultiCheck = selectedValues => {
    setSelectedFacilities(selectedValues);
  };
  const handleOk = (patientNameAdd, bedId, patientId) => {
    if (patientNameAdd && selectedDate && facility) {
      const dateStr = selectedDate.format('YYYY-MM-DD');

      addPatientToRoster1(patientId);

      // setEvents(prevEvents => {
      //   const updatedEvents = { ...prevEvents };

      //   if (!updatedEvents[dateStr]) {
      //     updatedEvents[dateStr] = [];
      //   }

      //   updatedEvents[dateStr].push({
      //     patientName: patientNameAdd,
      //     facility: facility,
      //     bedId: bedId,
      //     patientId: patientId
      //   });

      //   return updatedEvents;
      // });
    }

    setPatientName('');

    setIsModalOpen(false); // Close the modal
  };

  const handleCancel = () => {
    setPatientName('');
    setDeleteModal(false);
    setIsModalOpen(false); // Close the modal
  };

  // const handleDateRangeChange = dates => {
  //   setDateRange(dates);
  // };
  const handleDateRangeChange = date => {
    if (date) {
      // const newDateRange = [date.startOf('week'), date.endOf('week')];
      const newDateRange = [
        date.clone().startOf('week'),
        date.clone().endOf('week')
      ];
      setSelectDate(date);

      setDateRange(newDateRange);
    }
  };
  const handleDateSelection = date => {
    handleDateClick(date);
  };
  const handleFacilityChange = value => {
    const selectedFacility = facilities.find(
      facility => facility.value === value
    );

    if (selectedFacility) {
      // Set both value and label
      setFacility({
        value: selectedFacility.value,
        label: selectedFacility.label
      });
    }
    setLoader1(true);
    getPatientList(value);
  };
  const getPatientList = (value, searchString = '') => {
    getPatient(value, searchString)
      .then(response => {
        if (response.data.success) {
          setPatients(
            response.data.result.map(item => {
              return {
                id: item.pid,
                name: item.firstName + ' ' + item.lastName,
                bedId: item.bedId
              };
            })
          );
          setLoader1(false);
          // setLoader(false);
        } else {
          if (selectedDate && selectedDate.isValid()) {
            setIsModalVisible(true);
            setPatients([]);
          }
          // notification['error']({
          //   message: 'Failed',
          //   description: response.data.message
          // });
        }
      })
      .catch(err => console.log(err));
  };
  const handleBillerAuthorChange = value => {
    const selectedBillerAuthor = billerAuthor.find(
      billerAuthor => billerAuthor.id === value
    );

    if (selectedBillerAuthor) {
      setAuthorId(selectedBillerAuthor.id);
      // Set both value and label
      setBiller({
        id: selectedBillerAuthor.id,
        fullName: selectedBillerAuthor.fullName
      });
    }
  };

  const handleMouseLeave = () => {
    setHoveredDate(null);
  };
  const getFacilities = (authorId, searchVal) => {
    setLoader(true);
    getUserFacilities(authorId, userData.userType, searchVal)
      .then(response => {
        if (response.data.success) {
          setSelectedFacilities(
            response.data.result.map(item => item.facilityId)
          );
          setFacilities(
            response.data.result.map(item => {
              return {
                label: item.facilityName,
                value: item.facilityId,
                disabled: item.disabled,
                className: item.disabled ? 'disabled-facility-option' : ''
              };
            })
          );
          setLoader(false);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
          setLoader(false);
        }
      })
      .catch(err => console.log(err));
  };
  const handleSearch = event => {
    const value = event.target.value; // Extract the value from the event

    setSearchString(value);
  };
  const handleSearchFacility = event => {
    const value = event.target.value; // Extract the value from the event

    setSearchStringFacility(value);
  };
  useEffect(() => {
    if (facilities.length === 1) {
      const singleFacility = facilities[0];
      setFacility({
        value: singleFacility.value,
        label: singleFacility.label
      });
      setLoader1(true);
      getPatient(singleFacility.value)
        .then(response => {
          if (response.data.success) {
            setPatients(
              response.data.result.map(item => {
                return {
                  id: item.pid,
                  name: item.firstName + ' ' + item.lastName,
                  bedId: item.bedId
                };
              })
            );
            setLoader1(false);
            // setLoader(false);
          } else {
            console.log(selectedDate.length > 0, selectedDate, 'len');
            // if (selectedDate && selectedDate.isValid()) {
            //   setIsModalVisible(true);
            //   setPatients([]);
            // }
          }
        })
        .catch(err => console.log(err));
    }
  }, [facilities]);
  useEffect(() => {
    if (facility) {
      getPatientList(facility.value, searchString);
    }
  }, [searchString]);
  useEffect(() => {
    if (authorId) {
      getFacilities(authorId, searchStringFacility);
    }
  }, [searchStringFacility]);
  const filteredFacilities = facilities.filter(facility =>
    facility.label.toLowerCase().includes(searchTerm)
  );
  const handleMouseEnter = date => {
    setHoveredDate(date);
  };
  const handleApplyFilter = () => {
    setLoader(true);
    getPatientRosterOfDoctor(
      Math.floor(dateRange[1].hour(12).minute(0).second(0).valueOf() / 1000),
      selectedFacilities,
      Math.floor(dateRange[0].hour(12).minute(0).second(0).valueOf() / 1000)
    ).then(response => {
      console.log('API response:', response);
      if (response.data.success) {
        if (facilities.length > 0) {
          setLoader(false);
        }
        const result = response.data.result;
        if (Array.isArray(result)) {
          const updatedEvents = handleApiResponse(response.data.result);
          setEvents(updatedEvents);
        } else {
          notification['error']({
            message: 'Failed',
            description: 'Unexpected data format received from the server.'
          });
        }
      } else {
        // If the API call fails but the request went through
        notification['error']({
          message: 'Failed',
          description:
            response.data.message ||
            'Failed to add patient to the roster. Please try again.'
        });
        setLoader(false);
      }
    });

    setIsDropdownVisible(false);
  };

  const handleApiResponse = response => {
    // if (!response || !Array.isArray(response.result)) {
    //   console.error('Invalid response format, expected an array:', response);
    //   return {};
    // }

    const events = {};

    response.forEach(item => {
      const dateStr = moment.unix(item.dateTime).format('YYYY-MM-DD');

      const eventData = {
        patientName: item.patientName,
        facility: {
          label: item.facility.facilityName
        },
        bedId: item.bedId,
        patientId: item.patientRosterId
      };

      if (!events[dateStr]) {
        events[dateStr] = [];
      }
      events[dateStr].push(eventData);
    });

    return events;
  };

  const handleCancelFilter = () => {
    // setSelectedFacilities([]);
    setIsDropdownVisible(false);
  };
  // const handleDelete = itemId => {
  //   // Call your delete API here
  //   console.log('Delete item with ID:', itemId);
  //   removePatientCard(itemId).then(response => {
  //     if (response.data.success) {
  //       setEvents();
  //       notification['success']({
  //         message: 'Success',
  //         description: 'Patient has been successfully deleted from the roster.'
  //       });
  //     } else {
  //       // If the API call fails but the request went through
  //       notification['error']({
  //         message: 'Failed',
  //         description:
  //           response.data.message || 'Failed to delete. Please try again.'
  //       });
  //     }
  //   });
  //   // Example API call:
  //   // axios.delete(`/api/items/${itemId}`).then(response => {
  //   //   // Handle successful delete
  //   // }).catch(error => {
  //   //   // Handle error
  //   // });
  // };
  const handleClearCheckboxes = () => {
    setSelectedFacilities([]);
  };
  const handleDelete = () => {
    removePatientCard(itemId).then(response => {
      if (response.data.success) {
        setDeleteModal(false);
        handleApplyFilter();
      }
    });
    setDeleteModal(false);
  };
  const handleDeleteModal = itemId => {
    setDeleteModal(true);
    setItemId(itemId);
  };
  const facilityMenu = (
    <div
      style={{
        borderRadius: '8px',
        padding: '10px',
        // boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
        border: '1px solid #d9d9d9',
        backgroundColor: 'white'
      }}
    >
      <FacilityFilterroster>
        <span
          style={{
            fontWeight: 'bold',
            paddingLeft: '5px'
          }}
        >
          Filter Facilities
        </span>
        <Button
          type='link'
          style={{ padding: '0', fontSize: '14px', color: '#1890ff' }}
          onClick={handleClearCheckboxes}
        >
          Clear
        </Button>
      </FacilityFilterroster>
      <SearchFacilityDivider></SearchFacilityDivider>
      <SearchInputRosterStyle
        key='search'
        placeholder='Search Facility'
        prefix={<SearchOutlined />}
        onChange={handleSearchFacility}
        style={{
          marginBottom: '10px'
        }}
      />
      <SearchFacilityDivider></SearchFacilityDivider>
      <Checkbox.Group
        value={selectedFacilities} // This binds the checked state
        onChange={handleMultiCheck}
        key='checkboxes'
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
          maxHeight: '400px', // Adjust this value as needed
          overflowY: 'auto'
        }}
      >
        {filteredFacilities
          .filter(facility => !facility.isParent) // Filter out parent items
          .map(facility => (
            <StyledCheckBox
              prefixCls='rosterFacilitycheckBox'
              key={facility.value}
              value={facility.value}
              checked={selectedFacilities.includes(facility.value)}
            >
              {facility.label}
            </StyledCheckBox>
          ))}
      </Checkbox.Group>
      <FacilityFilterFooter>
        <Button
          type='primary'
          style={{
            borderRadius: '20px',
            padding: '4px 20px',
            backgroundColor: '#1890ff',
            borderColor: '#1890ff'
          }}
          onClick={handleApplyFilter}
        >
          Apply
        </Button>
        <FacilityFilterButton
          style={{
            borderRadius: '20px',
            padding: '4px 20px',
            backgroundColor: '#f0f0f0',
            borderColor: '#d9d9d9'
          }}
          onClick={handleCancelFilter}
        >
          Cancel
        </FacilityFilterButton>
      </FacilityFilterFooter>
    </div>
  );

  return (
    <>
      <Content style={{ padding: '10px' }}>
        <RoasterRow
          gutter={[16, 16]}
          justify='space-between'
          align='middle'
          style={{
            width: '100%',
            backgroundColor: 'white',
            marginLeft: '0px'
          }}
        >
          {userData.userType === 'biller' ? (
            <Col span={4}>
              <DoctorSelect
                placeholder='Select Author'
                value={biller.fullName}
                onChange={handleBillerAuthorChange}
              >
                {billerAuthor.map(billerAuthor => (
                  <Option key={billerAuthor.id} value={billerAuthor.id}>
                    {billerAuthor.fullName}
                  </Option>
                ))}
              </DoctorSelect>
            </Col>
          ) : (
            <Col span={4}>
              <span className='rosterhead'>My Patient Roster</span>
            </Col>
          )}

          <DateRangeCol span={17}>
            <StyledDatePicker
              value={selectDate}
              onChange={handleDateRangeChange}
              picker='week'
              format={value => value.format('DD MMM YYYY')}
              size='default'
              style={{ backgroundColor: '#F4F4F5', borderRadius: '4px' }}
            />
          </DateRangeCol>
          <Col span={3}>
            <Dropdown
              overlay={facilityMenu}
              trigger={['click']}
              visible={isDropdownVisible}
              onVisibleChange={visible => setIsDropdownVisible(visible)}
            >
              <div className='test'>
                <FacilityButton
                  type='default'
                  icon={<FacilityFilterIcon />}
                  style={{
                    display: 'flex',

                    alignItems: 'center'
                  }}
                >
                  <span style={{ marginLeft: '8px' }}>Facility</span>
                </FacilityButton>
              </div>
            </Dropdown>
          </Col>
        </RoasterRow>
        {loader ? (
          <Spinner height={'20vh'} />
        ) : (
          <Row
            style={{
              height: 'calc(100vh - 200px)',
              width: '100%',
              display: 'flex'
            }}
          >
            {daysOfWeek.map(day => (
              <CustomCol
                key={day.format('YYYY-MM-DD')}
                onMouseEnter={() => handleMouseEnter(day.format('YYYY-MM-DD'))}
                onMouseLeave={handleMouseLeave}
                // onClick={() => handleDateSelection(day)}
                className={day.format('YYYY-MM-DD') === today ? 'today' : ''}
              >
                <div className='patient-sheduler'>
                  <Text className='day-text'>{day.format('ddd')}</Text>

                  <br />

                  <Text className='date-text'>{day.format('DD')}</Text>
                  {day.format('YYYY-MM-DD') === today && (
                    <Text className='today-text'>TODAY</Text>
                  )}
                </div>

                <div style={{ flexGrow: 1 }}>
                  <List
                    dataSource={events[day.format('YYYY-MM-DD')] || []}
                    renderItem={item => (
                      <List.Item className='patientdetails'>
                        <span className='patientNameCard'>
                          {item.patientName}
                          <CloseButton
                            onClick={e => handleDeleteModal(item.patientId)}
                            icon={<CloseOutlined className='closeIcon' />}
                          />
                        </span>
                        <div className='facilityNameCard'>
                          <span className='bedcard'>
                            {' '}
                            Bed
                            {item.bedId}{' '}
                          </span>{' '}
                          <span className='bedcard'>{item.facility.label}</span>
                        </div>
                      </List.Item>
                    )}
                  />

                  {userData.userType !== 'biller' &&
                    (day.format('YYYY-MM-DD') === hoveredDate ||
                      day.format('YYYY-MM-DD') === today) && (
                      <AddpatientButton
                        type='primary'
                        icon={<PlusOutlined className='bold-icon' />}
                        onClick={() => handleDateSelection(day)}
                      >
                        Add Patient
                      </AddpatientButton>
                    )}
                </div>

                <div style={{ textAlign: 'center', marginTop: '10px' }}></div>
              </CustomCol>
            ))}
          </Row>
        )}
        <AuthorizationModal
          visible={isModalVisible}
          onCancel={handlePccCancel}
          footer={null}
          centered
          className='authorization-modal'
        >
          <div className='modal-content'>
            <div className='icon'>
              <div className='icon-wrapper'>
                <ExclamationCircleOutlined className='warning-icon' />
              </div>
            </div>
            <AuthorizationModalContent>
              <h3 style={{ textAlign: 'center' }}>
                You are not authorized to edit this. Please complete the
                authorization
              </h3>
              <p>
                You are not authorized to edit the roster of this facility.
                Please complete the authorization and to edit this roster.
              </p>
              <Button
                type='primary'
                className='authorization-button'
                onClick={handlePccOk}
              >
                Complete Authorisation
              </Button>
            </AuthorizationModalContent>
          </div>
        </AuthorizationModal>
        <ConfirmationModal
          visible={deleteModal}
          onOk={handleDelete}
          onCancel={handleCancel}
          // maskStyle={{ backgroundColor: 'transparent' }}

          centered
          // loading={deleteLoading}
          confirmation={true}
          antdModalProps={{ closable: false, mask: false }}
        >
          <DeleteIcon />
          <DeleteConfirmationMessage>
            Are you sure you want to delete?
          </DeleteConfirmationMessage>
        </ConfirmationModal>
        <StyledModal
          title='Add Patient'
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <FacilitySelect
            placeholder='Select facility'
            value={facility}
            onChange={handleFacilityChange}
          >
            {facilities.map(facility => (
              <Option key={facility.value} value={facility.value}>
                {facility.label}
              </Option>
            ))}
          </FacilitySelect>
          <StyledDivider />
          <SearchInputRosterStyle
            placeholder='Search Patient'
            prefix={<SearchOutlined />}
            onChange={handleSearch}
            // style={{
            //   marginBottom: '10px'
            // }}
          />
          <StyledDivider />
          {loader1 ? (
            <Spinner height={'2vh'} />
          ) : (
            patients.length > 0 && (
              <StyledList
                dataSource={patients}
                renderItem={patient => (
                  <List.Item
                    actions={[
                      <AddButton
                        type='primary'
                        icon={<PlusOutlined className='bold-icon' />}
                        onClick={() =>
                          handleOk(patient.name, patient.bedId, patient.id)
                        }
                      >
                        Add
                      </AddButton>
                    ]}
                  >
                    <List.Item.Meta
                      avatar={<Avatar>{patient.name[0]}</Avatar>}
                      title={patient.name}
                    />
                  </List.Item>
                )}
                style={{
                  maxHeight: '400px',
                  overflowY: 'auto'
                }}
              />
            )
          )}
        </StyledModal>
      </Content>
    </>
  );
};

export default Roaster;
